import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { brandingConfig } from '../config'; // Adjust the path based on your file location

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');     // Remove the stored token
    localStorage.removeItem('userRole');  // Remove the user role
    navigate('/login');                   // Redirect to login page
  };

  return (
    <div className="sidebar bg-dark text-white" style={{ width: '250px', minHeight: '100vh' }}>
      <div className="text-center py-4">
        {/* KitLab Logo */}
        <img
          src={brandingConfig.mainLogo}
          alt="KitLab Logo"
          style={{ width: '100px', marginBottom: '20px' }}
        />
        {/* Partner Logo */}
        <img
          src={brandingConfig.partnerLogo}
          alt={brandingConfig.partnerName}
          style={{ width: '100px', marginBottom: '20px' }}
        />
      </div>

      <Nav className="flex-column p-3">
        <Nav.Item>
          <Nav.Link as={Link} to="/dashboard" className="text-white">Dashboard</Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link as={Link} to="/all-orders" className="text-white">All Orders</Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link as={Link} to="/matched-orders" className="text-white">All Orders</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/shopify-orders" className="text-white">Shopify Orders</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/spectrapath-orders" className="text-white">SpectraPath Orders</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/pmi-orders" className="text-white">Labsender Orders</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/manage-users" className="text-white">Manage Users</Nav.Link>
        </Nav.Item>

        {/* Logout Button */}
        <Nav.Item className="mt-4">
          <button
            onClick={handleLogout}
            className="btn btn-outline-light w-100"
          >
            Logout
          </button>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default Sidebar;
