import React, { useEffect, useState } from 'react';
import { Table, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Sidebar from './Sidebar';

const PMITable = () => {
    const [pmiOrders, setPMIOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPMIOrders = async () => {
            try {
                const response = await axios.get('/api/pmi/getPMIInfo');
                setPMIOrders(response.data.orders); // Assume orders are in the response data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching PMI orders:', error);
                setLoading(false);
            }
        };

        fetchPMIOrders();
    }, []);

    const formatDateString = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
    };

    return (

        <div className="d-flex">
      <Sidebar />
        <div>
            <h2 className="mb-4">PMI Orders</h2>
            {loading ? (
                <Spinner animation="border" />
            ) : (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Specimen ID</th>
                            <th>Test Code</th>
                            <th>Registered Date</th>
                            <th>Processed Date</th>
                            <th>Received Date</th>
                            <th>Resulted Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(pmiOrders).length > 0 ? (
                            Object.keys(pmiOrders).map((specimenId, index) => {
                                const order = pmiOrders[specimenId];
                                const { order_statuses = {} } = order; // Default to an empty object if undefined

                                return (
                                    <tr key={index}>
                                        <td>{order.specimen_id || 'N/A'}</td>
                                        <td>{order.test_code || 'N/A'}</td>
                                        <td>{formatDateString(order_statuses['kit-registered'])}</td>
                                        <td>{formatDateString(order_statuses['lab-started-testing'])}</td>
                                        <td>{formatDateString(order_statuses['lab-received-kit'])}</td>
                                        <td>{formatDateString(order_statuses['lab-completed-testing'])}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="7">No orders found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}
        </div>
        </div>

    );
};

export default PMITable;
