import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, ButtonGroup, Container, Row, Col, Table, Spinner, FormControl } from 'react-bootstrap';
import './SpectraPathOrders.css';
import Sidebar from './Sidebar';

const SpectraPathOrders = () => {
    const [spectraPathOrders, setSpectraPathOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]); // For search filter
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateRangeOption, setDateRangeOption] = useState('thisMonth');
    const [searchTerm, setSearchTerm] = useState(''); // To store search input
    const [loading, setLoading] = useState(false);

    const fetchOrders = (start, end) => {
        setLoading(true);
        axios.get(`/api/spectrapath-orders?startDate=${start}&endDate=${end}`)
            .then(response => {
                setSpectraPathOrders(response.data);
                setFilteredOrders(response.data); // Initialize filtered orders
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching SpectraPath orders:', error);
                setLoading(false);
            });
    };

    const handleDateRangeChange = (option) => {
        const today = new Date();
        let newStartDate = today;
        let newEndDate = today;

        if (option === 'thisWeek') {
            const dayOfWeek = today.getDay();
            newStartDate = new Date(today.setDate(today.getDate() - dayOfWeek));
            newEndDate = new Date();
        } else if (option === 'lastWeek') {
            const dayOfWeek = today.getDay();
            newStartDate = new Date(today.setDate(today.getDate() - dayOfWeek - 7));
            newEndDate = new Date(today.setDate(today.getDate() + 6));
        } else if (option === 'thisMonth') {
            newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
            newEndDate = new Date();
        }

        setStartDate(newStartDate.toISOString().split('T')[0]);
        setEndDate(newEndDate.toISOString().split('T')[0]);
        setDateRangeOption(option);
        fetchOrders(newStartDate.toISOString().split('T')[0], newEndDate.toISOString().split('T')[0]);
    };

    useEffect(() => {
        handleDateRangeChange('thisMonth');
    }, []);

    // Search filter function
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = spectraPathOrders.filter(order =>
            order.orderNumber.toLowerCase().includes(term) ||
            order.reference.toLowerCase().includes(term) ||
            order.shipTo.toLowerCase().includes(term)
        );
        setFilteredOrders(filtered);
    };

    return (
        <div className="d-flex">
      <Sidebar /> {/* Sidebar on the left */}
        <Container className="mt-4">
            <Row>
                <Col className="text-center">
                    <h2>SpectraPath Orders</h2>
                    <div className="date-range-selector mt-3">
                        <ButtonGroup aria-label="Date range selection">
                            <Button 
                                variant={dateRangeOption === 'today' ? 'primary' : 'outline-primary'} 
                                onClick={() => handleDateRangeChange('today')}>
                                Today
                            </Button>
                            <Button 
                                variant={dateRangeOption === 'thisWeek' ? 'primary' : 'outline-primary'} 
                                onClick={() => handleDateRangeChange('thisWeek')}>
                                This Week
                            </Button>
                            <Button 
                                variant={dateRangeOption === 'lastWeek' ? 'primary' : 'outline-primary'} 
                                onClick={() => handleDateRangeChange('lastWeek')}>
                                Last Week
                            </Button>
                            <Button 
                                variant={dateRangeOption === 'thisMonth' ? 'primary' : 'outline-primary'} 
                                onClick={() => handleDateRangeChange('thisMonth')}>
                                This Month
                            </Button>
                        </ButtonGroup>
                        <input 
                            type="date" 
                            value={startDate} 
                            onChange={e => setStartDate(e.target.value)} 
                            className="mx-2 date-input" 
                        />
                        <input 
                            type="date" 
                            value={endDate} 
                            onChange={e => setEndDate(e.target.value)} 
                            className="date-input" 
                        />
                    </div>
                </Col>
            </Row>

            {/* Search Bar */}
            <Row className="mt-3">
                <Col className="text-center">
                    <FormControl
                        type="text"
                        placeholder="Search by Order Number, Reference, or Ship To"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="w-50 mx-auto"
                    />
                </Col>
            </Row>

            {/* Order Table */}
            <Row className="mt-4">
                <Col>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="primary" />
                            <p>Loading SpectraPath Orders...</p>
                        </div>
                    ) : filteredOrders && filteredOrders.length > 0 ? (
                        <Table striped bordered hover responsive className="mt-4 shadow custom-table">
                            <thead className="table-primary sticky-header">
                                <tr>
                                    <th>Order Number</th>
                                    <th>Reference</th>
                                    <th>Ship To</th>
                                    <th>Status</th>
                                    <th>Tracking Number</th>
                                    <th>Order Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOrders.map(order => (
                                    <tr key={order.orderNumber}>
                                        <td>{order.orderNumber}</td>
                                        <td>{order.reference}</td>
                                        <td>{order.shipTo}</td>
                                        <td>{order.orderStatus || 'Pending'}</td> {/* Assuming status exists */}
                                        <td>{order.outboundTracking.shipService || 'N/A'}</td> {/* Assuming trackingNumber exists */}
                                        <td>{new Date(order.orderDateTime).toLocaleDateString() || 'N/A'}</td> {/* Assuming orderDate exists */}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p className="text-center">No SpectraPath orders found for the selected date range.</p>
                    )}
                </Col>
            </Row>
        </Container>
        </div>
    );
};

export default SpectraPathOrders;
