import React from 'react';
import './HomePage.css';  // Importing CSS file for styling

const HomePage = () => {
  return (
    <div className="landing-page">
      {/* Navbar */}
      <nav className="navbar">
        <div className="navbar-logo">
          <a href="#home">Kitrack</a>
        </div>
        <ul className="navbar-links">
          <li><a href="#home">Home</a></li>
          <li><a href="#features">Features</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
        <div className="navbar-login">
          <a href="/login" className="btn-secondary">Login</a>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="hero" id="home">
        <div className="hero-content">
          <h1>Welcome to Kitrack</h1>
          <p>Your one-stop solution for tracking and managing your kits efficiently.</p>
          <a href="#features" className="btn-primary">Explore Features</a>
        </div>
      </section>

      {/* Features Section */}
      <section className="features" id="features">
        <h2>Why Choose Kitrack?</h2>
        <div className="features-grid">
          <div className="feature-item">
            <h3>Real-Time Tracking</h3>
            <p>Track your kits in real time with our intuitive tracking system.</p>
          </div>
          <div className="feature-item">
            <h3>Easy Management</h3>
            <p>Manage all your kits and inventories in one place effortlessly.</p>
          </div>
          <div className="feature-item">
            <h3>Customizable Reports</h3>
            <p>Generate custom reports to get the insights you need to streamline operations.</p>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="about" id="about">
        <h2>About Kitrack</h2>
        <p>Kitrack is designed to make it easy for you to manage your kits, inventory, and logistics, so you can focus on what matters most. Our system is built with simplicity and efficiency in mind, ensuring you can track and monitor everything from a single dashboard.</p>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonials-grid">
          <div className="testimonial-item">
            <p>"Kitrack has transformed how we manage our kits. It's simple, efficient, and saves us so much time!"</p>
            <h4>- Sarah M.</h4>
          </div>
          <div className="testimonial-item">
            <p>"The real-time tracking and customizable reports have been a game changer for our business."</p>
            <h4>- John D.</h4>
          </div>
          <div className="testimonial-item">
            <p>"I love how easy it is to manage everything from one place. Highly recommend!"</p>
            <h4>- Lisa R.</h4>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact" id="contact">
        <h2>Contact Us</h2>
        <form className="contact-form">
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" placeholder="Your Message" rows="5" required></textarea>
          <button type="submit" className="btn-primary">Send Message</button>
        </form>
      </section>

      {/* Footer Section */}
      <footer>
        <div className="footer-content">
          <p>&copy; 2024 Kitrack. All rights reserved.</p>
          <div className="social-links">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
