import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Spinner, Table } from 'react-bootstrap';
import Sidebar from './Sidebar';

const ShopifyOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTrackingNumber, setSelectedTrackingNumber] = useState(null);
    const [trackingDetails, setTrackingDetails] = useState(null);
    const [showModal, setShowModal] = useState(false);

    // Fetch Shopify orders
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('/api/shopify-orders');
                setOrders(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching Shopify orders:', error);
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    // Fetch tracking details from AfterShip
    const fetchTrackingDetails = async (trackingNumber) => {
        try {
            const response = await axios.get(`/api/tracking/${trackingNumber}`);
            setTrackingDetails(response.data);
            setSelectedTrackingNumber(trackingNumber);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching tracking details:', error);
        }
    };

    if (loading) {
        return (
            <div className="d-flex">
      <Sidebar /> {/* Sidebar on the left */}
            <div className="text-center mt-4">
                <Spinner animation="border" variant="primary" />
                <p>Loading Shopify Orders...</p>
            </div>
            </div>

        );
    }

    return (
        <div className="d-flex">
      <Sidebar /> {/* Sidebar on the left */}
        <div className="container mt-4">
            <h3>Shopify Orders</h3>
            <Table striped bordered hover responsive className="mt-4 shadow custom-table">
                <thead className="table-primary sticky-header">
                    <tr>
                        <th>Order Number</th>
                        <th>Customer Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Order Date</th>
                        <th>Ordered Items</th>
                        <th>Tags</th>
                        <th>Shipping Status</th>
                        <th>Tracking Link</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr key={order.id}>
                            <td>{order.name}</td>
                            <td>{order.customer ? `${order.customer.first_name} ${order.customer.last_name}` : 'N/A'}</td>
                            <td>{order.customer?.email || 'N/A'}</td>
                            <td>{order.customer?.phone || 'N/A'}</td>
                            <td>{new Date(order.created_at).toLocaleDateString()}</td>
                            <td>
                                {order.line_items?.map((item) => (
                                    <div key={item.id}>
                                        {item.name} (x{item.quantity})
                                    </div>
                                ))}
                            </td>
                            <td>{order.tags || 'N/A'}</td>
                            <td>{order.fulfillment_status || 'Pending'}</td>
                            <td>
    {order.fulfillments?.map((fulfillment) => (
        <div key={fulfillment.tracking_number}>
            <Button
                variant="link"
                href={order.order_status_url} // Use the Shopify order_status_url here
                target="_blank"
            >
                Shopify Tracking Link
            </Button>
        </div>
    ))}
</td>


                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal to show tracking details */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Tracking Details for {selectedTrackingNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {trackingDetails ? (
                        <>
                            <div style={{ marginBottom: '10px' }}>
                                <a
                                    href={trackingDetails.tracking_url_provider}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Full Tracking Details
                                </a>
                            </div>

                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trackingDetails.checkpoints.map((checkpoint, index) => (
                                        <tr key={index}>
                                            <td>{new Date(checkpoint.checkpoint_time).toLocaleString()}</td>
                                            <td>{checkpoint.message}</td>
                                            <td>{checkpoint.location}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    ) : (
                        <p>No tracking details available.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        </div>

    );
};

export default ShopifyOrders;
