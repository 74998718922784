import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// Function to get unique tracking details by tracking number
const getUniqueTrackingDetails = (trackingDetails) => {
  const uniqueDetails = [];
  trackingDetails.forEach(detail => {
      if (detail && detail.tracking_number) { // Ensure detail and tracking_number are defined
          uniqueDetails.push(detail);
      }
  });
  return uniqueDetails;
};


// Component for USPS-like Shipment Timeline (both outbound and return)
const ShipmentTimelines = ({ trackingDetails, shipmentType }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  if (!trackingDetails || trackingDetails.length === 0) {
    return <div>No {shipmentType} tracking events available.</div>;
  }

  // Filter unique tracking numbers
  const uniqueTrackingDetails = getUniqueTrackingDetails(trackingDetails);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <h4 style={{ fontSize: '1.25rem', marginBottom: '10px' }}>{shipmentType} Shipment Timeline</h4>
      <div className="timeline-container" style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {uniqueTrackingDetails.map((tracking, index) => (
          <div
            key={index}
            style={{
              marginBottom: '15px',
              width: '100%',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div
              className="tracking-summary"
              onClick={() => toggleAccordion(index)}
              style={{
                padding: '10px 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                backgroundColor: '#f8f9fa',
              }}
            >
              <div>
                <h5 style={{ fontSize: '1rem', margin: '0' }}>
                  Tracking Number: {tracking.tracking_number}
                </h5>
                {tracking.status && (
                  <p style={{ margin: '0', fontSize: '0.875rem', color: '#6c757d' }}>
                    Status: {tracking.status}
                  </p>
                )}
              </div>
              <FontAwesomeIcon
                icon={expandedIndex === index ? faChevronUp : faChevronDown}
                style={{ fontSize: '1.25rem', color: '#007bff' }}
              />
            </div>
            {expandedIndex === index && (
              <div
                className="tracking-details"
                style={{ padding: '15px', borderTop: '1px solid #e0e0e0', backgroundColor: '#ffffff' }}
              >
                {tracking.shipDate && (
                  <p style={{ margin: '0', fontSize: '0.875rem', color: '#6c757d' }}>
                    Ship Date: {tracking.shipDate}
                  </p>
                )}
                {tracking.deliveryDate && (
                  <p style={{ margin: '0', fontSize: '0.875rem', color: '#6c757d' }}>
                    Delivery Date: {tracking.deliveryDate}
                  </p>
                )}
                {tracking.signedBy && (
                  <p style={{ margin: '0', fontSize: '0.875rem', color: '#6c757d' }}>
                    Signed By: {tracking.signedBy}
                  </p>
                )}
                <div
                  className="tracking-events-container"
                  style={{
                    borderLeft: '2px solid #007bff',
                    paddingLeft: '15px',
                    marginLeft: '10px',
                    marginTop: '10px',
                    maxHeight: '200px',
                    overflowY: 'auto',
                  }}
                >
                  {tracking.history && tracking.history.length > 0 ? (
                    tracking.history.map((event, idx) => (
                      <div
                        key={idx}
                        className="tracking-event"
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: '10px',
                          position: 'relative',
                          marginBottom: '10px',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={event.activity === 'Delivered' ? faCheckCircle : faCircle}
                          style={{
                            color: event.activity === 'Delivered' ? '#28a745' : '#007bff',
                            fontSize: '0.75rem',
                            position: 'absolute',
                            left: '-23px',
                          }}
                        />
                        <div>
                          <p style={{ margin: '0', fontWeight: 'bold', fontSize: '0.8rem' }}>
                            {event.activity}
                          </p>
                          {event.dateTime && (
                            <p style={{ margin: '0', fontSize: '0.75rem', color: '#6c757d' }}>
                              {event.dateTime}
                            </p>
                          )}
                          {event.location && (
                            <p style={{ margin: '0', fontSize: '0.75rem', color: '#6c757d' }}>
                              {event.location}
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p style={{ fontSize: '0.875rem', color: '#6c757d', marginTop: '5px' }}>
                      No tracking events available for tracking number {tracking.tracking_number}.
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShipmentTimelines;
