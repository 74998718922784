import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import EventTimeline from './EventTimeline';
import ShipmentTimelines from './ShipmentTimelines';
import Sidebar from './Sidebar';

const MatchedOrderDetails = () => {
    const { orderName } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const addKL8Prefix = (name) => {
        return name.startsWith('KL8') ? name : `KL8${name}`;
    };

    useEffect(() => {
        if (orderName) {
            setLoading(true);
            setError(null);
            const prefixedOrderName = addKL8Prefix(orderName);
            axios.get(`/api/matched-orders/${prefixedOrderName}`)
                .then(response => {
                    console.log("Data received in frontend:", JSON.stringify(response.data, null, 2));
                    setOrderDetails(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching order details:', error);
                    setError('Failed to fetch order details.');
                    setLoading(false);
                });
        }
    }, [orderName]);

    if (loading) {
        return <Spinner animation="border" />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!orderDetails || !orderDetails.shopifyOrder) {
        return <p>No order details available.</p>;
    }

    const { shopifyOrder, spectrapathOrder, pmiDetails, outboundTrackingDetails, returnTrackingDetails } = orderDetails;
    if (spectrapathOrder?.error?.ErrorMessage === "Unique results not found") {
        return <p>Order cancelled.</p>;
    } else if (spectrapathOrder?.error?.ErrorMessage === "Order Not Found") {
        return <p>Order not found in SpectraPath.</p>;
    }
    
    const formattedPMIDetails = Array.isArray(pmiDetails) ? pmiDetails : [];
    // Ensure we’re accessing the first PMI entry in the array for the timeline
const pmiData = formattedPMIDetails?.[0] || {};  // Fallback to empty object if undefined
const labSenderStatus = pmiData?.labSenderStatus || {};  // Fallback if labSenderStatus is missing

const mainTimelineEvents = (pmi) => [
    {
        label: 'Shopify Order Placed',
        date: shopifyOrder?.created_at ? new Date(shopifyOrder.created_at).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(shopifyOrder?.created_at),
    },
    {
        label: 'Order Sent to SpectraPath',
        date: spectrapathOrder?.orderDetails?.orderDateTime ? new Date(spectrapathOrder.orderDetails.orderDateTime).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(spectrapathOrder?.orderDetails?.orderDateTime),
    },
    {
        label: 'SpectraPath Ships Order',
        date: spectrapathOrder?.orderDetails?.outboundTracking?.[0]?.shipDate 
            ? new Date(spectrapathOrder.orderDetails.outboundTracking[0].shipDate).toLocaleDateString("en-US", { timeZone: "UTC" })
            : 'N/A',
        status: Boolean(spectrapathOrder?.orderDetails?.outboundTracking?.[0]?.shipDate),
    },
    {
        label: 'Order Delivered to Customer',
        date: (() => {
            // Check if the carrier is UPS in outbound tracking, then use outboundTrackingDetails delivery date
            const carrier = spectrapathOrder?.orderDetails?.outboundTracking?.[0]?.shipMethod;
            if (carrier && carrier.toLowerCase() === 'ups') {
                return outboundTrackingDetails?.[0]?.deliveryDate 
                    ? new Date(outboundTrackingDetails[0].deliveryDate).toLocaleDateString("en-US", { timeZone: "UTC" })
                    : 'N/A';
            }
            
            // If carrier is USPS, check for "Out for Delivery" activity if no delivery date is available
            if (carrier && carrier.toLowerCase() === 'usps') {
                const outForDelivery = outboundTrackingDetails?.[0]?.history?.find(entry => entry.activity.includes('Out for Delivery'));
                if (outForDelivery) {
                    return outForDelivery.dateTime
                        ? new Date(outForDelivery.dateTime).toLocaleDateString("en-US", { timeZone: "UTC" })
                        : 'N/A';
                }
            }
    
            // Otherwise, fall back to the standard delivery date in SpectraPath items
            return spectrapathOrder?.orderDetails?.items?.[0]?.deliverDate 
                ? new Date(spectrapathOrder.orderDetails.items[0].deliverDate).toLocaleDateString("en-US", { timeZone: "UTC" })
                : 'N/A';
        })(),
        status: (() => {
            const carrier = spectrapathOrder?.orderDetails?.outboundTracking?.[0]?.shipMethod;
            if (carrier && carrier.toLowerCase() === 'ups') {
                return Boolean(outboundTrackingDetails?.[0]?.deliveryDate);
            }
            
            if (carrier && carrier.toLowerCase() === 'usps') {
                const outForDelivery = outboundTrackingDetails?.[0]?.history?.find(entry => entry.activity.includes('Out for Delivery'));
                return Boolean(outForDelivery?.dateTime);
            }
    
            return Boolean(spectrapathOrder?.orderDetails?.items?.[0]?.deliverDate);
        })(),
    },
    
    {
        label: 'Customer Registers Kit',
        date: pmiDetails[0]?.labSenderStatus?.['kit-registered'] ? new Date(pmiDetails[0].labSenderStatus['kit-registered']).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(pmiDetails[0]?.labSenderStatus?.['kit-registered']),
    },
    {
        label: 'Requisition Request Sent to Cynergy',
        date: pmiDetails[0]?.labSenderStatus?.['requisition-sent-to-cynergy'] ? new Date(pmiDetails[0].labSenderStatus['requisition-sent-to-cynergy']).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(pmiDetails[0]?.labSenderStatus?.['requisition-sent-to-cynergy']),
    },
    {
        label: 'Requisition Approved',
        date: pmiDetails[0]?.labSenderStatus?.['requisition-approved'] ? new Date(pmiDetails[0].labSenderStatus['requisition-approved']).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(pmiDetails[0]?.labSenderStatus?.['requisition-approved']),
    },

    {
        label: 'Customer Ships Back Kit to Lab',
        date: (() => {
            // Check if the return carrier is USPS, then use the dateTime for "USPS picked up item"
            const returnCarrier = spectrapathOrder?.orderDetails?.returnTracking?.[0]?.shipMethod;
            if (returnCarrier && returnCarrier.toLowerCase() === 'usps') {
                const pickedUpItem = returnTrackingDetails?.[0]?.history?.find(entry => entry.activity === 'USPS picked up item');
                console.log('Using returnTrackingDetails history:', returnTrackingDetails?.[0]?.history); // Log full history
                return pickedUpItem?.dateTime
                    ? new Date(pickedUpItem.dateTime).toLocaleDateString("en-US", { timeZone: "UTC" })
                    : 'N/A';
            }
            // Otherwise, fall back to the standard ship date in returnTrackingDetails
            return returnTrackingDetails?.[0]?.shipDate
                ? new Date(returnTrackingDetails[0].shipDate).toLocaleDateString("en-US", { timeZone: "UTC" })
                : 'N/A';
        })(),
        status: (() => {
            const returnCarrier = spectrapathOrder?.orderDetails?.returnTracking?.[0]?.shipMethod;
            if (returnCarrier && returnCarrier.toLowerCase() === 'usps') {
                const pickedUpItem = returnTrackingDetails?.[0]?.history?.find(entry => entry.activity === 'USPS picked up item');
                return Boolean(pickedUpItem?.dateTime);
            }
            return Boolean(returnTrackingDetails?.[0]?.shipDate);
        })(),
    },
    
    
    
    
    {
        label: 'Kit Delivered to Lab',
        date: spectrapathOrder?.orderDetails?.returnTracking?.[0]?.deliverDate 
            ? new Date(spectrapathOrder.orderDetails.returnTracking[0].deliverDate).toLocaleDateString("en-US", { timeZone: "UTC" })
            : 'N/A',
        status: Boolean(spectrapathOrder?.orderDetails?.returnTracking?.[0]?.deliverDate),
    },
    {
        label: 'Lab Receives Kit',
        date: pmiDetails[0]?.labSenderStatus?.['lab-received-kit'] ? new Date(pmiDetails[0].labSenderStatus['lab-received-kit']).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(pmiDetails[0]?.labSenderStatus?.['lab-received-kit']),
    },
    {
        label: 'Lab Starts Testing',
        date: pmiDetails[0]?.labSenderStatus?.['lab-started-testing'] ? new Date(pmiDetails[0].labSenderStatus['lab-started-testing']).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(pmiDetails[0]?.labSenderStatus?.['lab-started-testing']),
    },
    {
        label: 'Lab Completes Testing',
        date: pmiDetails[0]?.labSenderStatus?.['lab-completed-testing'] ? new Date(pmiDetails[0].labSenderStatus['lab-completed-testing']).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(pmiDetails[0]?.labSenderStatus?.['lab-completed-testing']),
    },
    {
        label: 'Lab Sends Results to Cynergy',
        date: pmiDetails[0]?.labSenderStatus?.['sent-results-to-cynergy'] ? new Date(pmiDetails[0].labSenderStatus['sent-results-to-cynergy']).toLocaleDateString("en-US", { timeZone: "UTC" }) : 'N/A',
        status: Boolean(pmiDetails[0]?.labSenderStatus?.['sent-results-to-cynergy']),
    },
    {
        label: 'Completed',
        date: pmiDetails[0]?.labSenderStatus?.['order-completed'] && pmiDetails[0].labSenderStatus['order-completed'] !== 'N/A'
            ? new Date(pmiDetails[0].labSenderStatus['order-completed']).toLocaleDateString("en-US", { timeZone: "UTC" })
            : 'N/A',
        status: pmiDetails[0]?.labSenderStatus?.['order-completed'] && pmiDetails[0].labSenderStatus['order-completed'] !== 'N/A',
    }
];

    return (
        <div className="d-flex">
            <Sidebar />
            <div className="container mt-4">
                <h2>Order Details for {orderName}</h2>
                <div className="pmi-details">
                <h4>PMI Details</h4>
                {formattedPMIDetails.map((pmi, index) => (
                    <p key={index}>
                        <strong>PMI:</strong> 
                        <a href={pmi.orderUrl} target="_blank" rel="noopener noreferrer">
                            {pmi.kitLotNumber}
                        </a>
                        {' '}(<strong>Test Code:</strong> {pmi.testCode})
                    </p>
                ))}
            </div>


{pmiDetails.map((pmi, index) => (
    <div key={index}>
        <h5>Timeline for PMI {pmi.kitLotNumber}</h5>
        <EventTimeline events={mainTimelineEvents(pmi)} />
    </div>
))}
                <ShipmentTimelines trackingDetails={outboundTrackingDetails} shipmentType="Outbound" />
                <ShipmentTimelines trackingDetails={returnTrackingDetails} shipmentType="Return" />

                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Shopify Order Details</Card.Title>
                        <p><strong>Order Number:</strong> {shopifyOrder.order_number}</p>
                        <p><strong>Order Date:</strong> {new Date(shopifyOrder.created_at).toLocaleDateString()}</p>
                        <p><strong>Customer Email:</strong> {shopifyOrder.email || 'N/A'}</p>
                        <p><strong>Financial Status:</strong> {shopifyOrder.financial_status}</p>
                        <p><strong>Fulfillment Status:</strong> {shopifyOrder.fulfillment_status}</p>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default MatchedOrderDetails;
