import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, userRole, allowedRoles }) => {
  if (!userRole) {
    // If userRole is not present, redirect to login
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(userRole)) {
    // If the user's role is not allowed, redirect to a different page (e.g., customer dashboard)
    return <Navigate to="/customer-dashboard" />;
  }

  return children;  // If all checks pass, render the children (protected component)
};

export default ProtectedRoute;
