import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Spinner, Table, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from './Sidebar';

import uspsLogo from '../assets/usps.png'; // Replace with your actual path
import upsLogo from '../assets/ups.png';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register the components for the chart
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const AdminDashboard = () => {
    const [stats, setStats] = useState({
        totalOrders: 0,
        totalIncome: 0,
        totalPending: 0,
        averageProcessingTime: '',
        kitCounts: {},
        bestSellingKit: '',
        processingTimes: [],
        salesBreakdown: {},
        totalPMIs: 0,
        pmisDeliveredNotReceived: 0,
        pmisReceivedNotStarted: 0,
        pmisInProgress: 0,
        unshippedCount: 0,
    maxUnshippedTime: 0
    });
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30))); // 30 days ago
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1))); // Tomorrow
    

    const fetchStats = async (startDate, endDate) => {
        try {
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];
            const response = await axios.get(`/api/stats?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
            setStats(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching stats:', error);
            setLoading(false);
        }
    };
    

    useEffect(() => {
        fetchStats(startDate, endDate);
    }, [startDate, endDate]);

    // Prepare data for the line chart
    const salesData = {
        labels: Object.keys(stats.salesBreakdown), // Dates
        datasets: [
            {
                label: 'Total Sales ($)',
                data: Object.values(stats.salesBreakdown).map(data => data.totalSales),
                borderColor: 'rgba(75,192,192,1)',
                fill: false,
                tension: 0.3,
                pointRadius: 2, // Smaller points
            },
            {
                label: 'Order Count',
                data: Object.values(stats.salesBreakdown).map(data => data.orderCount),
                borderColor: 'rgba(153,102,255,1)',
                fill: false,
                tension: 0.3,
                pointRadius: 2, // Smaller points
            }
        ]
    };

    const chartOptions = {
        maintainAspectRatio: false, // Disable aspect ratio to control height and width
        scales: {
            y: {
                beginAtZero: true,
            }
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 10, // Smaller legend font size
                    }
                }
            }
        }
    };

    // Helper function to check if a date is a weekend (Friday, Saturday, or Sunday)
const isWeekend = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDay(); // 0 is Sunday, 5 is Friday, 6 is Saturday
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    if (day === 0 || day === 5 || day === 6) {
        return `Weekend - ${daysOfWeek[day]}`;
    }
    return null;
};

// Function to calculate the difference in hours between now and the order date
const getHoursSinceOrderPlaced = (orderDate) => {
    const now = new Date();
    const orderTime = new Date(orderDate);
    const diffInMs = now - orderTime;
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert milliseconds to hours
    return diffInHours;
};



    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const navigateToPmiDetails = (status) => {
        const formattedStatus = status.replace(/ /g, '-').toLowerCase();
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
    
        navigate(`/pmi-details/${formattedStatus}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
    };
    
    const [sortOption, setSortOption] = useState('date-desc'); // Default: Newest to Oldest


    return (
        <div className="d-flex">
      <Sidebar /> {/* Sidebar on the left */}
        <div>
            <h2 className="mb-4" style={{ fontSize: '1.5rem' }}>Dashboard Overview</h2>

            {/* Date pickers for selecting the timeframe */}
            <Row className="mb-2">
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control form-control-sm" />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} className="form-control form-control-sm" />
                    </Form.Group>
                </Col>
            </Row>

{/* Row 1: Total Orders, Total Income, Best Selling Kit */}
<Row className="mt-3">
    <Col md={4}>
        <Card className="shadow-sm" style={{ padding: '10px' }}>
            <Card.Body>
                <h6>Total Orders</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.totalOrders}</h3>}
            </Card.Body>
        </Card>
    </Col>
    <Col md={4}>
        <Card className="shadow-sm" style={{ padding: '10px' }}>
            <Card.Body>
                <h6>Total Income</h6>
                {loading ? <Spinner animation="border" /> : <h3>${stats.totalIncome.toLocaleString()}</h3>}
            </Card.Body>
        </Card>
    </Col>
    <Col md={4}>
        <Card className="shadow-sm" style={{ padding: '10px' }}>
            <Card.Body>
                <h6>Best Selling Kit</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.bestSellingKit || 'N/A'}</h3>}
            </Card.Body>
        </Card>
    </Col>
</Row>

{/* Row 2: Total PMIs, PMIs Registered Not Completed, PMIs Delivered Not Received */}
<Row className="mt-3">
    <Col md={4}>
        <Card
            className="shadow-sm"
            style={{ padding: '10px', cursor: 'pointer' }}
            onClick={() => navigateToPmiDetails('Total PMIs')}
        >
            <Card.Body>
                <h6>Total PMIs</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.totalPMIs}</h3>}
            </Card.Body>
        </Card>
    </Col>
    <Col md={4}>
        <Card
            className="shadow-sm"
            style={{ padding: '10px', cursor: 'pointer' }}
            onClick={() => navigateToPmiDetails('PMIs Registered Not Completed')}
        >
            <Card.Body>
                <h6>PMIs Registered Not Completed</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.pmisRegisteredNotCompleted}</h3>}
            </Card.Body>
        </Card>
    </Col>
    <Col md={4}>
        <Card
            className="shadow-sm"
            style={{ padding: '10px', cursor: 'pointer' }}
            onClick={() => navigateToPmiDetails('PMIs Delivered Not Received')}
        >
            <Card.Body>
                <h6>PMIs Delivered Not Received</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.pmisDeliveredNotReceived}</h3>}
            </Card.Body>
        </Card>
    </Col>
</Row>

{/* Row 3: PMIs Received Not Started, PMIs In Progress, Kit Orders */}
<Row className="mt-3">
    <Col md={4}>
        <Card
            className="shadow-sm"
            style={{ padding: '10px', cursor: 'pointer' }}
            onClick={() => navigateToPmiDetails('PMIs Received Not Started')}
        >
            <Card.Body>
                <h6>PMIs Received Not Started</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.pmisReceivedNotStarted}</h3>}
            </Card.Body>
        </Card>
    </Col>
    <Col md={4}>
        <Card
            className="shadow-sm"
            style={{ padding: '10px', cursor: 'pointer' }}
            onClick={() => navigateToPmiDetails('PMIs In Progress')}
        >
            <Card.Body>
                <h6>PMIs In Progress</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.pmisInProgress}</h3>}
            </Card.Body>
        </Card>
    </Col>
    <Col md={4}>
        <Card className="shadow-sm" style={{ padding: '10px' }}>
            <Card.Body>
                <h6>Total Kits Ordered</h6>
                {loading ? <Spinner animation="border" /> : (
                    <ul style={{ fontSize: '0.9rem' }}>
                        {Object.keys(stats.kitCounts).map((kit, index) => (
                            <li key={index}>{kit}: {stats.kitCounts[kit]} units</li>
                        ))}
                    </ul>
                )}
            </Card.Body>
        </Card>
    </Col>
</Row>

<Row className="mt-3">
    <Col md={6}>
        <Card className="shadow-sm" style={{ padding: '10px' }}>
            <Card.Body>
                <h6>Total Unshipped Orders</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.unshippedCount}</h3>}
            </Card.Body>
        </Card>
    </Col>
    <Col md={6}>
        <Card className="shadow-sm" style={{ padding: '10px' }}>
            <Card.Body>
                <h6>Longest Unshipped Processing Time (Hours)</h6>
                {loading ? <Spinner animation="border" /> : <h3>{stats.maxUnshippedTime} hours</h3>}
            </Card.Body>
        </Card>
    </Col>
</Row>



            {/* Line chart to display sales analytics */}
            {/* <Row className="mt-3">
                <Col>
                    <Card className="shadow-sm">
                        <Card.Body style={{ height: '300px' }}> 
                            <h6>Sales Analytics</h6>
                            {loading ? <Spinner animation="border" /> : (
                                <Line data={salesData} options={chartOptions} />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row> */}

            {/* Table to display matched orders with processing times */}

            
            <Row className="mt-3">
                <Col>
                    <Card className="shadow-sm" style={{ padding: '10px' }}>
                        <Card.Body>
                            <h6>Order Processing Times</h6>
                            <Form.Group className="mb-3">
    <Form.Label>Sort Orders By:</Form.Label>
    <Form.Select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
        <option value="date-desc">Date (Newest to Oldest)</option>
        <option value="date-asc">Date (Oldest to Newest)</option>
        <option value="unshipped">Unshipped Orders First</option>
        <option value="time-asc">Processing Time (Lowest to Highest)</option>
        <option value="time-desc">Processing Time (Highest to Lowest)</option>
    </Form.Select>
</Form.Group>

                            {loading ? (
                                <Spinner animation="border" />
                            ) : (
                                <Table striped bordered hover responsive className="table-light mt-3">
                                    <thead>
                                        <tr>
                                            <th>Order Number</th>
                                            <th>Customer Name</th>
                                            <th>Shopify Order Date/Time</th>
                                            <th>SpectraPath Order Date/Time</th>
                                            <th>SpectraPath Shipped Date/Time</th>
                                            <th>Outbound Ship Method</th>
                                            <th>Tracking Number</th>
                                            <th>Processing Time (hours)</th>
                                        </tr>
                                    </thead>


                                    <tbody>
{stats.totalOrdersList.length > 0 ? (
    stats.totalOrdersList
        .sort((a, b) => {
            const spectrapathOrderA = stats.processingTimes.find(o => o.orderNumber === a.name);
            const spectrapathOrderB = stats.processingTimes.find(o => o.orderNumber === b.name);

            const processingTimeA = spectrapathOrderA?.processingTime || Infinity;
            const processingTimeB = spectrapathOrderB?.processingTime || Infinity;

            const isUnshippedA = !spectrapathOrderA?.spectrapathShippedDateTime;
            const isUnshippedB = !spectrapathOrderB?.spectrapathShippedDateTime;

            switch (sortOption) {
                case 'date-asc':
                    return new Date(a.created_at) - new Date(b.created_at);
                case 'date-desc':
                    return new Date(b.created_at) - new Date(a.created_at);
                case 'unshipped':
                    return isUnshippedB - isUnshippedA;
                case 'time-asc':
                    return processingTimeA - processingTimeB;
                case 'time-desc':
                    return processingTimeB - processingTimeA;
                default:
                    return 0;
            }
        })
        .map((order, index) => {
            const spectrapathOrder = stats.processingTimes.find(o => o.orderNumber === order.name);
            const isUnshipped = !spectrapathOrder || !spectrapathOrder.spectrapathShippedDateTime;

            let shipDate = spectrapathOrder?.spectrapathShippedDateTime
                ? new Date(spectrapathOrder.spectrapathShippedDateTime)
                : null;
            const orderDate = new Date(order.created_at);

            // Adjust ship date if it's earlier than the order date
            if (shipDate && shipDate < orderDate) {
                shipDate.setDate(shipDate.getDate() + 1);
            }

            // Calculate processing time if shipped, otherwise calculate time since order was placed
         const fulfillmentTime = order.fulfillments?.[0]?.created_at
    ? new Date(order.fulfillments[0].created_at)
    : null;

const processingTime = fulfillmentTime
    ? Math.round((fulfillmentTime - orderDate) / (1000 * 60 * 60)) // Difference in hours
    : Math.round((new Date() - orderDate) / (1000 * 60 * 60)); // For unshipped orders


            // Determine if the order was placed on a weekend
            const weekendText = isWeekend(order.created_at);

            const processingTimeText = isUnshipped
                ? `Unshipped (${processingTime} hours${weekendText ? ` - ${weekendText}` : ''})`
                : `${processingTime} hours${weekendText ? ` (${weekendText})` : ''}`;

            const cellColor = isUnshipped ? 'orange' : processingTime > 24 ? 'red' : 'inherit';

            return (
                <tr key={index}>
                    <td>{order.name} </td>
                    <td>{`${order.customer?.first_name} ${order.customer?.last_name}`}</td>
                    <td>
    {new Date(order.created_at).toLocaleString('en-US', {
        weekday: 'long',
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true // Ensures AM/PM format
    })}
</td>                   <td>{spectrapathOrder?.spectrapathOrderDateTime || 'N/A'}</td>
<td>
    {order.fulfillments?.[0]?.created_at
        ? new Date(order.fulfillments[0].created_at).toLocaleString('en-US', {
              weekday: 'long',
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true // Ensures AM/PM format
          })
        : 'N/A'}
</td>                    <td>{spectrapathOrder?.outboundShipMethod || 'N/A'}</td>
                    <td>{spectrapathOrder?.trackingNumber || 'N/A'}</td>
                    <td
                        style={{
                            color: cellColor,
                            fontWeight: isUnshipped || processingTime > 24 ? 'bold' : 'normal'
                        }}
                        title={isUnshipped ? 'Order not shipped yet' : processingTime > 24 ? 'Processing time exceeded 24 hours' : ''}
                    >
                        {processingTimeText}
                    </td>
                </tr>
            );
        })
) : (
    <tr>
        <td colSpan="8">No data available</td>
    </tr>
)}
</tbody>




                                </Table>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
        </div>

    );
};

export default AdminDashboard;
