import React from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import './EventTimeline.css';

const EventTimeline = ({ events }) => {
    const steps = [
        "Shopify order placed",
        "Order sent to SpectraPath",
        "SpectraPath ships order",
        "Order delivered to customer",
        "Customer registers kit",
        "Requisition request sent to Cynergy",
        "Requisition approved",
        "Customer ships back kit to lab",
        "Kit delivered to lab",
        "Lab receives kit",
        "Lab starts testing",
        "Lab completes testing",
        "Lab sends results to Cynergy",
        "Completed"
    ];

    console.log("Events received in EventTimeline:", events); // Log events for debugging

    const getStatus = (stepIndex) => {
        // Ensure stepIndex is within bounds of the events array
        if (events[stepIndex] && events[stepIndex].status === true) {
            return "completed";
        } else {
            return "upcoming";
        }
    };

    return (
        <Container>
            <Row className="timeline-row">
                {steps.map((step, index) => (
                    <Col key={index} className="timeline-step">
                        <div className={`timeline-dot ${getStatus(index)}`} />
                        <div className="timeline-label">
                            <Badge bg={getStatus(index) === "completed" ? "success" : "warning"}>
                                {step}
                            </Badge>
                            {/* Check if events[index] exists before accessing date */}
                            {events[index] && events[index].date ? (
                                <p className="event-date">{events[index].date}</p>
                            ) : (
                                <p className="event-date">N/A</p>
                            )}
                        </div>
                        {index < steps.length - 1 && <div className={`timeline-line ${getStatus(index)}`} />}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default EventTimeline;
