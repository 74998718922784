import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner, Table, Row, Col, Form, Badge } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from './Sidebar';

const PmiDetailsView = () => {
    const { status } = useParams();
    const { search } = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(search);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(
        query.get('startDate') ? new Date(query.get('startDate')) : new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );
    const [endDate, setEndDate] = useState(query.get('endDate') ? new Date(query.get('endDate')) : new Date());

    // Fetch PMI data
    const fetchPMIData = async () => {
        setLoading(true);
        try {
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];
            const endpoint = `/api/${status}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
            const response = await axios.get(endpoint);
            setData(response.data.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPMIData();
    }, [status, startDate, endDate]);

    const handleStatusChange = (event) => {
        const newStatus = event.target.value;
        navigate(`/pmi-details/${newStatus}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
    };

    return (
        <div className="d-flex">
            <Sidebar />
            <div className="container-fluid">
                <h2 className="mt-4">{status.replace(/-/g, ' ').toUpperCase()} Details</h2>

                {/* Date Range Picker */}
                <Row className="mb-4">
                    <Col md={4}>
                        <Form.Label>Start Date</Form.Label>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                    </Col>
                    <Col md={4}>
                        <Form.Label>End Date</Form.Label>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" />
                    </Col>
                    <Col md={4}>
                        <Form.Label>Status</Form.Label>
                        <Form.Select onChange={handleStatusChange} value={status}>
                            <option value="total-pmis">Total PMIs</option>
                            <option value="pmis-delivered-not-received">PMIs Delivered Not Received</option>
                            <option value="pmis-received-not-started">PMIs Received Not Started</option>
                            <option value="pmis-in-progress">PMIs In Progress</option>
                            <option value="pmis-registered-not-completed">PMIs Registered Not Completed</option>
                        </Form.Select>
                    </Col>
                </Row>

                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Table striped bordered hover responsive className="table-light mt-3">
                        <thead className="sticky-top bg-primary text-white">
                            <tr>
                                <th>Order Number</th>
                                <th>Kit Lot Number</th>
                                <th>SpectraPath Status</th>
                                <th>LabSender Status</th>
                                <th>RSL Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? (
                                data.map((item, index) => {
                                    const orderNumber =
                                        item.shopifyOrderNumber && item.shopifyOrderNumber !== 'N/A'
                                            ? item.shopifyOrderNumber
                                            : item.spectrapathOrderNumber && item.spectrapathOrderNumber !== 'N/A'
                                            ? item.spectrapathOrderNumber
                                            : 'N/A';

                                    return (
                                        <tr key={index}>
                                            <td>
                                                {orderNumber !== 'N/A' ? (
                                                    <a
                                                        href={`/matched-orders/${orderNumber}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {orderNumber}
                                                    </a>
                                                ) : (
                                                    'N/A'
                                                )}
                                            </td>
                                            <td>{item.kitLotNumber}</td>
                                            <td>
                                                <Badge
                                                    bg={
                                                        item.spectrapathStatus === 'In Transit'
                                                            ? 'primary'
                                                            : item.spectrapathStatus === 'Pending Pickup'
                                                            ? 'warning'
                                                            : 'secondary'
                                                    }
                                                >
                                                    {item.spectrapathStatus}
                                                </Badge>
                                            </td>
                                            <td>
                                                {typeof item.labSenderStatus === 'object'
                                                    ? Object.entries(item.labSenderStatus).map(([key, value]) => (
                                                          <div key={key}>
                                                              {key}: <span>{value || 'N/A'}</span>
                                                          </div>
                                                      ))
                                                    : item.labSenderStatus}
                                            </td>
                                            <td>
                                                {item.rslNumber ? (
                                                    <a
                                                        href={`https://trackingcarrier.com/${item.rslNumber}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-primary"
                                                    >
                                                        {item.rslNumber}
                                                    </a>
                                                ) : (
                                                    'N/A'
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="5">No data available for this status.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                )}
            </div>
        </div>
    );
};

export default PmiDetailsView;
