import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Form, Row, Col, Button, Toast, Spinner, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';

const MatchedOrders = () => {
    const [matchedOrders, setMatchedOrders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [dateRangeOption, setDateRangeOption] = useState('thisMonth');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const navigate = useNavigate();

    const fetchMatchedOrders = (start, end, searchTerm = '') => {
        setLoading(true);
        axios.get(`/api/matched-orders?startDate=${start}&endDate=${end}&searchTerm=${encodeURIComponent(searchTerm)}`)
            .then(response => {
                setMatchedOrders(response.data);
                setFilteredOrders(response.data); // Reset filteredOrders with fresh data
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching matched orders:', error);
                setLoading(false);
            });
    };

    const handleDateRangeChange = (option) => {
        const today = new Date();
        let newStartDate = today;
        let newEndDate = today;

        if (option === 'today') {
            newStartDate = newEndDate;
        } else if (option === 'thisWeek') {
            const dayOfWeek = today.getDay();
            newStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek);
            newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek + 6); // Set to end of the week
        }
        
         else if (option === 'lastWeek') {
            const dayOfWeek = today.getDay();
            newEndDate = new Date(today.setDate(today.getDate() - dayOfWeek - 1));
            newStartDate = new Date(today.setDate(today.getDate() - 6));
        } else if (option === 'thisMonth') {
            newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
            newEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1); // Set end date to tomorrow
        }
        

        setStartDate(newStartDate.toISOString().split('T')[0]);
        setEndDate(newEndDate.toISOString().split('T')[0]);
        setDateRangeOption(option);
        fetchMatchedOrders(newStartDate.toISOString().split('T')[0], newEndDate.toISOString().split('T')[0], searchTerm);
    };

    const handleCustomDateChange = (e, type) => {
        const value = e.target.value;
        if (type === 'start') {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    };

    const handleBlurCustomDate = () => {
        if (startDate && endDate) {
            fetchMatchedOrders(startDate, endDate, searchTerm);
        }
    };

    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setEndDate(tomorrow.toISOString().split('T')[0]);
        handleDateRangeChange('thisWeek');
    }, []);
    

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        fetchMatchedOrders(startDate, endDate, term);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setToastMessage(`Copied: ${text}`);
        setShowToast(true);
    };

    const getPMIStatuses = (pmiOrder) => {
        const statusOrder = [
            "results-completed",
            "order-completed",
            "sent-results-to-cynergy",
            "lab-completed-testing",
            "lab-started-testing",
            "lab-received-kit",
            "requisition-approved",
            "requisition-sent-to-cynergy",
            "kit-registered"
        ];
    
        return statusOrder
            .map(status => {
                const date = pmiOrder.order_statuses[status] ? new Date(pmiOrder.order_statuses[status]) : null;
                return date ? { status, date } : null;
            })
            .filter(entry => entry !== null)
            .sort((a, b) => a.date - b.date);
    };
    

    return (
        <div className="d-flex">
            <Sidebar />
            <div className="container mt-4">
                <h2 className="mb-4 text-primary">All Orders Across Platforms</h2>

                <Toast
                    style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 1 }}
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                >
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>

                <Row>
                    <Col className="text-center">
                        <ButtonGroup aria-label="Date range selection">
                            <Button
                                variant={dateRangeOption === 'today' ? 'primary' : 'outline-primary'}
                                onClick={() => handleDateRangeChange('today')}
                            >
                                Today
                            </Button>
                            <Button
                                variant={dateRangeOption === 'thisWeek' ? 'primary' : 'outline-primary'}
                                onClick={() => handleDateRangeChange('thisWeek')}
                            >
                                This Week
                            </Button>
                            <Button
                                variant={dateRangeOption === 'lastWeek' ? 'primary' : 'outline-primary'}
                                onClick={() => handleDateRangeChange('lastWeek')}
                            >
                                Last Week
                            </Button>
                            <Button
                                variant={dateRangeOption === 'thisMonth' ? 'primary' : 'outline-primary'}
                                onClick={() => handleDateRangeChange('thisMonth')}
                            >
                                This Month
                            </Button>
                        </ButtonGroup>
                        <input
                            type="date"
                            value={startDate}
                            onChange={e => handleCustomDateChange(e, 'start')}
                            onBlur={handleBlurCustomDate}
                            className="mx-2 date-input"
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={e => handleCustomDateChange(e, 'end')}
                            onBlur={handleBlurCustomDate}
                            className="date-input"
                        />
                    </Col>
                </Row>

                <Row className="mb-3 mt-3">
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Search orders by customer name, order number, reference, PMI, or RSL..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="form-control-lg shadow-sm"
                        />
                    </Col>
                </Row>

                {!loading ? (
                    <Table striped bordered hover responsive className="shadow table-hover">
                        <thead className="table-primary">
                            <tr>
                                <th>Shopify Order Number</th>
                                <th>Customer Name</th>
                                <th>SpectraPath Reference</th>
                                <th>LabSender Status</th>
                                <th>Outbound Tracking</th>
                                <th>PMI (Kit Lot Number)</th>
                                <th>Return Tracking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrders.map(({ shopifyOrder, spectrapathOrder, pmiOrder }, index) => {
                                const spectrapathPMIs = spectrapathOrder?.outboundTracking?.map(tracking => tracking.kitLotNumber?.replace(/-/g, '').trim().toLowerCase()) || ['N/A'];
                                const labsenderPMI = pmiOrder?.specimen_id?.replace(/-/g, '').trim().toLowerCase() || 'N/A';
                                const isPMIMatched = spectrapathPMIs.some(kitLotNumber => kitLotNumber === labsenderPMI);

                                return (
                                    <tr key={index} className="align-middle">
                                        
                                        <td>
    <OverlayTrigger placement="top" overlay={<Tooltip>Copy Order Number</Tooltip>}>
        <span
            className="font-weight-bold text-primary"
            onClick={() => copyToClipboard(shopifyOrder?.name || spectrapathOrder?.orderNumber || 'N/A')}
        >
            <a
                href={`/matched-orders/${shopifyOrder?.name || spectrapathOrder?.orderNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'blue', textDecoration: 'none' }}
            >
                {shopifyOrder?.name || spectrapathOrder?.orderNumber || 'N/A'}
            </a>
        </span>
    </OverlayTrigger>
    <br />
    <small className="text-muted">
        {
            shopifyOrder?.created_at 
                ? new Date(shopifyOrder.created_at).toLocaleString() 
                : spectrapathOrder?.orderDateTime 
                    ? new Date(spectrapathOrder.orderDateTime).toLocaleString() 
                    : 'N/A'
        }
    </small>
</td>

                                        <td>{shopifyOrder?.customer?.first_name || spectrapathOrder?.shipTo} {shopifyOrder?.customer?.last_name || ''}</td>
                                        <td>
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Copy SpectraPath Reference</Tooltip>}>
                                                <span onClick={() => copyToClipboard(spectrapathOrder?.orderNumber || 'N/A')}>
                                                    {spectrapathOrder?.orderNumber || 'N/A'}
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                        <td>
                                            {pmiOrder?.order_statuses 
                                                ? (() => {
                                                    const statusOrder = [
                                                        "results-completed",
                                                        "order-completed",
                                                        "sent-results-to-cynergy",
                                                        "lab-completed-testing",
                                                        "lab-started-testing",
                                                        "lab-received-kit",
                                                        "requisition-approved",
                                                        "requisition-sent-to-cynergy",
                                                        "kit-registered"
                                                    ];

                                                    const latestStatus = statusOrder
                                                        .map(status => ({
                                                            status,
                                                            date: pmiOrder.order_statuses[status] ? new Date(pmiOrder.order_statuses[status]) : null
                                                        }))
                                                        .find(({ date }) => date && !isNaN(date));

                                                    if (latestStatus) {
                                                        const formattedStatus = latestStatus.status
                                                            .replace(/-/g, ' ')
                                                            .replace(/\b\w/g, char => char.toUpperCase());

                                                        return (
                                                            <div>
                                                                <strong>{formattedStatus}:</strong> {latestStatus.date.toLocaleString()}
                                                            </div>
                                                        );
                                                    } else {
                                                        return 'No LabSender Status';
                                                    }
                                                })()
                                                : 'No LabSender Status'}
                                        </td>

                                        {/* Outbound Tracking */}
                                        <td>
                                            {spectrapathOrder?.outboundTracking && spectrapathOrder.outboundTracking.length > 0 ? (
                                                spectrapathOrder.outboundTracking.map((tracking, i) => (
                                                    <div key={i} className="mb-2">
                                                        <strong>Ship Date: </strong>{tracking.shipDate ? new Date(tracking.shipDate).toLocaleDateString() : 'N/A'}<br />
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Copy Outbound Tracking</Tooltip>}>
                                                            <strong onClick={() => copyToClipboard(tracking.trackingNumber || 'N/A')}>Tracking #: {tracking.trackingNumber || 'N/A'}</strong>
                                                        </OverlayTrigger><br />
                                                        <strong>Status: </strong>{tracking.status || 'N/A'}<br />
                                                    </div>
                                                ))
                                            ) : 'N/A'}
                                        </td>

                                        {/* PMI (Kit Lot Number) and LabSender Status */}
                                        <td>
                                            {spectrapathOrder?.outboundTracking && spectrapathOrder.outboundTracking.length > 0 ? (
                                                spectrapathOrder.outboundTracking.map((tracking, i) => (
                                                    <div key={i} className="mb-2">
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Copy PMI (Kit Lot Number)</Tooltip>}>
                                                            <span onClick={() => copyToClipboard(tracking.kitLotNumber || 'N/A')}>{tracking.kitLotNumber || 'N/A'}</span>
                                                        </OverlayTrigger><br />
                                                    </div>
                                                ))
                                            ) : 'N/A'}
                                        </td>

                                        {/* Return Tracking */}
                                        <td>
                                            {spectrapathOrder?.returnTracking && spectrapathOrder.returnTracking.length > 0 ? (
                                                spectrapathOrder.returnTracking.map((tracking, i) => {
                                                    const isUSPS = tracking.shipMethod === 'USPS';
                                                    let shipDate;

                                                    if (isUSPS) {
                                                        if (tracking.history && tracking.history.length > 1 && tracking.history[1].dateTime) {
                                                            shipDate = tracking.history[1].dateTime;
                                                        } else {
                                                            shipDate = 'N/A';
                                                        }
                                                    } else {
                                                        shipDate = tracking.shipDate ? new Date(tracking.shipDate).toLocaleDateString() : 'N/A';
                                                    }

                                                    const deliverDate = tracking.deliverDate ? new Date(tracking.deliverDate).toLocaleDateString() : 'N/A';

                                                    return (
                                                        <div key={i} className="mb-2">
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Copy RSL (Return Shipping Label)</Tooltip>}>
                                                                <strong onClick={() => copyToClipboard(tracking.trackingNumber || 'N/A')}>
                                                                    RSL: {tracking.trackingNumber || 'N/A'}
                                                                </strong>
                                                            </OverlayTrigger><br />
                                                            <strong>Ship Date: </strong>{shipDate}<br />
                                                            <strong>Delivery Date: </strong>{deliverDate}<br />
                                                            <strong>Status: </strong>{tracking.status || 'N/A'}<br />
                                                        </div>
                                                    );
                                                })
                                            ) : 'N/A'}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <Spinner animation="border" variant="primary" />
                )}
            </div>
        </div>
    );
};

export default MatchedOrders;
