import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MatchedOrders from './components/MatchedOrders';
import ShopifyOrders from './components/ShopifyOrders';
import SpectraPathOrders from './components/SpectraPathOrders';
import AdminDashboard from './components/AdminDashboard';
import MatchedOrderDetails from './components/MatchedOrderDetails';
import PmiOrders from './components/PmiOrders';
import PmiDetailsView from './components/PmiDetails';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import ManageUsers from './components/ManageUsers';
import AllOrders from './components/AllOrders';

const App = () => {
    const userRole = localStorage.getItem('userRole');

    return (
        <Router>
            <Routes>
                {/* Public Routes */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/" element={<HomePage />} />

                {/* Protected Routes */}
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                            <AdminDashboard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/matched-orders"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                            <MatchedOrders />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/shopify-orders"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                            <ShopifyOrders />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/spectrapath-orders"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                            <SpectraPathOrders />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/pmi-orders"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                            <PmiOrders />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/all-orders"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                            <AllOrders />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/matched-orders/:orderName"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                            <MatchedOrderDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/pmi-details/:status"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                                <PmiDetailsView />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/manage-users"
                    element={
                        <ProtectedRoute userRole={userRole} allowedRoles={['admin', 'manager']}>
                            <ManageUsers />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
