import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // For password toggle icons
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();  // For navigation

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
       const response = await axios.post('/api/auth/login', { email, password });
       const token = response.data.token;
       const userRole = response.data.role;
 
       console.log("Login Response:", response.data);  // Log the response to check role and token
 
       localStorage.setItem('token', token);  // Save token to localStorage
       localStorage.setItem('userRole', userRole);  // Save role to localStorage
 
       // Redirect based on user role
       if (userRole === 'admin' || userRole === 'manager') {
          navigate('/dashboard');
       } else {
          navigate('/customer-dashboard');
       }
    } catch (error) {
       console.error("Login error:", error);
       setErrorMessage('Login failed. Please check your credentials and try again.');
    }
 };
 
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form
        onSubmit={handleLogin}
        style={{
          width: '300px',
          padding: '30px',
          border: '1px solid #ccc',
          borderRadius: '10px',
          boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Login</h2>

        {errorMessage && <p style={{ color: 'red', textAlign: 'center', marginBottom: '15px' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green', textAlign: 'center', marginBottom: '15px' }}>{successMessage}</p>}

        <div style={{ marginBottom: '20px' }}>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              border: '1px solid #ccc',
              borderRadius: '5px',
            }}
            required
          />
        </div>

        <div style={{ marginBottom: '20px', position: 'relative' }}>
          <label>Password</label>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              marginTop: '5px',
              border: '1px solid #ccc',
              borderRadius: '5px',
            }}
            required
          />
          <span
            onClick={handlePasswordVisibility}
            style={{
              position: 'absolute',
              right: '10px',
              top: '40px',
              cursor: 'pointer',
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>

        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Login
        </button>

        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <a href="/forgot-password" style={{ color: '#007bff', textDecoration: 'none' }}>
            Forgot password?
          </a>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
