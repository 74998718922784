import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Form, Row, Col, Button, Spinner, ButtonGroup } from 'react-bootstrap';
import Sidebar from './Sidebar';

const AllOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateRangeOption, setDateRangeOption] = useState('thisMonth');
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch orders with date range
    const fetchAllOrders = async (start, end) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/all-orders', {
                params: { startDate: start, endDate: end },
            });
            setOrders(response.data);
        } catch (error) {
            console.error('Error fetching all orders:', error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };

    // Date range logic
    const handleDateRangeChange = (option) => {
        const today = new Date();
        let newStartDate = today;
        let newEndDate = today;

        if (option === 'thisWeek') {
            const dayOfWeek = today.getDay();
            newStartDate = new Date(today.setDate(today.getDate() - dayOfWeek));
            newEndDate = new Date();
        } else if (option === 'lastWeek') {
            const dayOfWeek = today.getDay();
            newStartDate = new Date(today.setDate(today.getDate() - dayOfWeek - 7));
            newEndDate = new Date(today.setDate(today.getDate() + 6));
        } else if (option === 'thisMonth') {
            newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
            newEndDate = new Date();
        }

        setStartDate(newStartDate.toISOString().split('T')[0]);
        setEndDate(newEndDate.toISOString().split('T')[0]);
        setDateRangeOption(option);
        fetchAllOrders(newStartDate.toISOString().split('T')[0], newEndDate.toISOString().split('T')[0]);
    };

    useEffect(() => {
        // Fetch orders for the current month on load
        handleDateRangeChange('thisMonth');
    
        // Log the orders data after it’s fetched to verify the structure
        console.log("Orders data after fetch:", orders);
    }, [orders]); // Adding orders as a dependency to log changes
    

    const getCurrentStatus = (trackingDetails) => {
        if (!trackingDetails || !trackingDetails.history || trackingDetails.history.length === 0) return 'N/A';
        const lastUpdate = trackingDetails.history[trackingDetails.history.length - 1];
        return lastUpdate ? lastUpdate.activity : 'N/A';
    };

    return (
        <div className="d-flex">
            <Sidebar /> {/* Sidebar on the left */}
            <div className="container mt-4">
                <h2 className="mb-4">All Orders from Shopify and SpectraPath</h2>

                {/* Date range selector */}
                <Row>
                    <Col className="text-center">
                        <ButtonGroup aria-label="Date range selection">
                            <Button
                                variant={dateRangeOption === 'today' ? 'primary' : 'outline-primary'}
                                onClick={() => handleDateRangeChange('today')}
                            >
                                Today
                            </Button>
                            <Button
                                variant={dateRangeOption === 'thisWeek' ? 'primary' : 'outline-primary'}
                                onClick={() => handleDateRangeChange('thisWeek')}
                            >
                                This Week
                            </Button>
                            <Button
                                variant={dateRangeOption === 'lastWeek' ? 'primary' : 'outline-primary'}
                                onClick={() => handleDateRangeChange('lastWeek')}
                            >
                                Last Week
                            </Button>
                            <Button
                                variant={dateRangeOption === 'thisMonth' ? 'primary' : 'outline-primary'}
                                onClick={() => handleDateRangeChange('thisMonth')}
                            >
                                This Month
                            </Button>
                        </ButtonGroup>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="mx-2"
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                        <Button onClick={() => fetchAllOrders(startDate, endDate)} variant="success" className="ml-2">
                            Fetch Orders
                        </Button>
                    </Col>
                </Row>

                {/* Orders Table */}
                {!loading ? (
                    <Table striped bordered hover responsive className="mt-4">
                        <thead className="table-primary">
                            <tr>
                                <th>Order Number</th>
                                <th>Customer Name</th>
                                <th>Order Date</th>
                                <th>Source</th>
                                <th>SpectraPath Reference</th>
                                <th>Ship To</th>
                                <th>PMI ID(s)</th>
                                <th>Outbound Status</th>
                                <th>Inbound Status</th>
                                <th>PMI Status</th>
                            </tr>
                        </thead>

                        <tbody>
    {(orders && orders.length > 0) ? (
        orders.map((order, index) => (
            <tr key={index}>
                {/* Order Number */}
                <td>{order?.shopifyOrder?.name || order?.spectrapathOrder?.orderDetails?.orderNumber || 'N/A'}</td>
                
                {/* Customer Name */}
                <td>
                    {order?.shopifyOrder
                        ? `${order.shopifyOrder.customer?.first_name || ''} ${order.shopifyOrder.customer?.last_name || ''}`
                        : order?.spectrapathOrder?.orderDetails?.shipTo || 'N/A'}
                </td>
                
                {/* Order Date */}
                <td>
                    {order?.shopifyOrder?.created_at 
                        ? new Date(order.shopifyOrder.created_at).toLocaleDateString() 
                        : (order?.spectrapathOrder?.orderDetails?.orderDateTime 
                            ? new Date(order.spectrapathOrder.orderDetails.orderDateTime).toLocaleDateString() 
                            : 'N/A')}
                </td>
                
                {/* Source */}
                <td>{order?.shopifyOrder ? 'Shopify' : (order?.spectrapathOrder ? 'SpectraPath' : 'N/A')}</td>
                
                {/* SpectraPath Reference */}
                <td>{order?.spectrapathOrder?.orderDetails?.reference || 'N/A'}</td>
                
                {/* Shipping Address */}
                <td>
                    {order?.shopifyOrder
                        ? `${order.shopifyOrder.shipping_address?.address1 || ''}, ${order.shopifyOrder.shipping_address?.city || ''}, ${order.shopifyOrder.shipping_address?.province || ''}`
                        : `${order?.spectrapathOrder?.orderDetails?.address1 || ''}, ${order?.spectrapathOrder?.orderDetails?.city || ''}, ${order?.spectrapathOrder?.orderDetails?.state || ''}`}
                </td>
                
                {/* PMIs */}
                <td>
                    {order?.pmiDetails && order.pmiDetails.length > 0
                        ? order.pmiDetails.map((pmi, i) => (
                            <span key={i}>{pmi.kitLotNumber || 'N/A'}{i < order.pmiDetails.length - 1 ? ', ' : ''}</span>
                        ))
                        : 'N/A'}
                </td>
                
                {/* Outbound Status */}
                <td>
                    {order?.outboundTrackingDetails && order.outboundTrackingDetails.length > 0
                        ? order.outboundTrackingDetails[0].status || 'N/A'
                        : 'N/A'}
                </td>
                
                {/* Return Status */}
                <td>
                    {order?.returnTrackingDetails && order.returnTrackingDetails.length > 0
                        ? order.returnTrackingDetails[0].status || 'N/A'
                        : 'N/A'}
                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan="9">No orders available</td>
        </tr>
    )}
</tbody>


                    </Table>
                ) : (
                    <Spinner animation="border" variant="primary" />
                )}
            </div>
        </div>
    );
};

export default AllOrders;
